import { BusinessEntity } from "Models/Entities";
import { store } from "Models/Store";
import { useState } from "react";
import alertToast from "Util/ToastifyUtils";
import EditMonitorDetailsModal from "../BusinessEntities/EditMonitorDetailsModal";
import If from "../If/If";
import { BusinessEntitySelector } from "./BusinessEntitySelector";
import SectionTitle from "./SectionTitle";

export const MonitorSection = () => {
	const isSuper = store.userType === 'SUPER_USER';
	const isManager = store.userType === 'ORGANISATION_MANAGER';
	const isBusinessEntityAdmin = store.userType === 'ADMIN';
	const isMonitorEnabled = store.getUser?.organisation?.intelEnabled;
	const showMonitorSetup = (isSuper || isManager || (isBusinessEntityAdmin && isMonitorEnabled)) && store.canAccessIntel;
	const [showBusinessEntitySelectorModal, setShowBusinessEntitySelectorModal] = useState(false);
	const [showProductModal, setShowProductModal] = useState(false);
	const [businessEntity, setBusinessEntity] = useState<BusinessEntity | undefined>(undefined); 

	const openModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.preventDefault();
		setShowBusinessEntitySelectorModal(true);
	}

	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.preventDefault();
		const link = e.currentTarget.querySelector('a');
		if (link) {
			link.click();
		}
	}

	const setLinkClass = () => {
		return store.canAccessIntel ? "hub-link" : "hub-inactive-link";
	  };

	return (
		<>
			<SectionTitle
				Link={store.canAccessIntel
					? '/monitor/dashboard'
					: store.appSettings.monitorMarketingUrl}
				Text="Monitor"
				DisplayButton={!isMonitorEnabled}
			/>
			<div className= "action-content extra-margin">
				<div className={setLinkClass()}>
					<div className="icon-left icon-person-add" />
					<a href="/monitor/customers/add" target="_blank" rel="noreferrer">
						Monitor new customer
					</a>
				</div>
				<div className={setLinkClass()}>
					<div className="icon-left icon-upload" />
					<a href="/monitor/upload/customers" target="_blank">
						Upload customers
					</a>
				</div>
				<div className={setLinkClass()}>
					<div className="icon-left icon-upload" />
					<a href="/monitor/upload/agedtrialbalance" target="_blank">
						Upload Aged Trial Balance
					</a>
				</div>

				<If condition={showMonitorSetup}>
					<div className="hub-link" onClick={isMonitorEnabled ? openModal : handleClick}>
						<div className="icon-left icon-settings" />
						<a href="/hub/organisation/products" target="_blank" rel="noreferrer">
							Configure monitoring
						</a>
					</div> 
				</If>
			</div>

			<If condition={showBusinessEntitySelectorModal}>
				<BusinessEntitySelector
					onClose={() => { setShowBusinessEntitySelectorModal(false); setBusinessEntity(undefined);}}
					onSelect={() => { setShowBusinessEntitySelectorModal(false); setShowProductModal(true) }}
					businessEntity={businessEntity}
					setBusinessEntity={setBusinessEntity}
					productFilter="monitor"
				/>
			</If>

			<If condition={showProductModal && businessEntity !== null}>
				<EditMonitorDetailsModal
					businessEntity={businessEntity ?? new BusinessEntity()}
					onClose={() => { setShowProductModal(false); setBusinessEntity(undefined); }}
					onSubmit={async () => {
						try {
							const savedEntity = await businessEntity?.saveWithFetchBack({
								monitorNotificationsEmail: {},
								enableEmailNotifications: {},
							}, {}, `
							`);
							setBusinessEntity(undefined);
							setShowProductModal(false);
							alertToast('Access Monitor successfully enabled', 'success');
						} catch (exception) {
							alertToast('Access Monitor could not be enabled, please refresh and try again', 'error');
						}
					}}
					addNew
				/>
			</If>

		</>
	)
}