import { SERVER_URL } from "Constants";
import { useValidation, Validation, Validator } from "Hooks/useValidation";
import { state } from "Models/Enums";
import { useMemo, useState } from "react";
import { Checkbox } from "Views/Components/Checkbox/Checkbox";
import { ComboboxOption } from "Views/Components/Combobox/Combobox";
import ComboboxSetter from "Views/Components/Combobox/ComboboxSetter";
import { DatePicker } from "Views/Components/DatePicker/DatePicker";
import If from "Views/Components/If/If";
import { NumberTextField } from "Views/Components/NumberTextBox/NumberTextBox";
import { TextFieldSetter } from "Views/Components/TextBox/TextFieldSetter";

export type FormItem = {
	name: string,
	type: 'text' | 'select' | 'date' | 'currency' | 'hidden',
	selectOptions?: string[],
	placeholder: string,
	label: string,
	isRequired: boolean,
	requiredText?: string,
	validate?: (validator: Validator<string | null | undefined>) => void
}

type FormProps = {
	items: FormItem[],
	validation: Validation,
	textFields: Record<string, string>,
	setTextFields: React.Dispatch<React.SetStateAction<Record<string, string>>>
}

const LodgeDefaultForm: React.FunctionComponent<FormProps> = ({items, validation, textFields, setTextFields}) => {
	const [showTermsAndDisclaimerModal, setShowTermsAndDisclaimerModal] = useState(false);

	return(
		<div>
			{items.map(item => (
				<>
					<If condition={item.type === 'text'}>
						<TextFieldSetter
							key={item.name}
							value={textFields[item.name]}
							setValue={v => setTextFields(prev => ({...prev, [item.name]: v}))}
							label={item.label}
							isRequired={item.isRequired}
							placeholder={item.placeholder}
							errors={validation.get(item.name, false)}
						/>
					</If>
					<If condition={item.type === 'select'}>
						<ComboboxSetter
							key={item.name}
							label={item.label}
							isRequired={item.isRequired}
							errors={validation.get(item.name, false)}
							getOptionValue={v => v}
							value={textFields[item.name]}
							setValue={v => setTextFields(prev => ({...prev, [item.name]: v}))}
							options={
								item.selectOptions?.map(option =>
									({ label: option, value: option, display: option } as ComboboxOption<string>)
								) ?? []
							}
						/>
					</If>
					<If condition={item.type === 'date'}>
						<DatePicker
							key={item.name}
							label={item.label}
							isRequired={item.isRequired}
							errors={validation.get(item.name, false)}
							model={{value: textFields[item.name]}}
							modelProperty="value"
							onAfterChange={dates => setTextFields(prev => ({...prev, [item.name]: dates[0].toLocaleDateString()}))}
						/>
					</If>
					<If condition={item.type === 'currency'}>
						<NumberTextField
							key={item.name}
							label={item.label + " ($)"}
							isRequired={item.isRequired}
							placeholder={item.placeholder}
							errors={validation.get(item.name, false)}
							model={{value: textFields[item.name]}}
							modelProperty="value"
							onAfterChange={v => 
								setTextFields(prev => 
									({...prev, [item.name]: v.target.value})
								)
							}
						/>
					</If>
				</>
			))}
			<Checkbox
				className="terms-label"
				label={<span>I confirm that I have read and understand the <a href={`${SERVER_URL}/api/files/document/defaultTerms`} target="_blank">terms and disclaimer"</a></span>}
				isRequired={true}
				model={{value: textFields["termsAndDisclaimer"]}}
				modelProperty="value"
				errors={validation.get('termsAndDisclaimer', false)}
				onChecked={(v, checked) => 
					setTextFields(prev => 
						({...prev, termsAndDisclaimer: checked ? `Agreed - ${new Date().toLocaleString()}` : 'Disagreed'}))}
			/>
			
		</div>
	)
};

export default LodgeDefaultForm