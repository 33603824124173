/*
 * @bot-written
 *
 * WARNING AND NOTICE
 * Any access, download, storage, and/or use of this source code is subject to the terms and conditions of the
 * Full Software Licence as accepted by you before being granted access to this source code and other materials,
 * the terms of which can be accessed on the Codebots website at https://codebots.com/full-software-licence. Any
 * commercial use in contravention of the terms of the Full Software Licence may be pursued by Codebots through
 * licence termination and further legal action, and be required to indemnify Codebots for any loss or damage,
 * including interest and costs. You are deemed to have accepted the terms of the Full Software Licence on any
 * access, download, storage, and/or use of this source code.
 *
 * BOT WARNING
 * This file is bot-written.
 * Any changes out side of "protected regions" will be lost next time the bot makes any changes.
 */
import { action, observable } from 'mobx';
import {
	Model,
	IModelAttributes,
	attribute,
	entity,
	ReferencePath,
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as Validators from 'Validators';
import { CRUD } from '../CRUDOptions';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	makeFetchManyToManyFunc,
	makeJoinEqualsFunc,
	makeFetchOneToManyFunc,
	makeEnumFetchFunction,
	getCreatedModifiedCrudOptions,
} from 'Util/EntityUtils';
import VisitorsUserEntity from 'Models/Security/Acl/VisitorsUserEntity';
import UserUserEntity from 'Models/Security/Acl/UserUserEntity';
import * as Enums from '../Enums';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import SuperAdministratorScheme from '../Security/Acl/SuperAdministratorScheme';
// % protected region % [Add any further imports here] on begin
import { computed } from 'mobx';
import { store } from 'Models/Store';
import { gql } from '@apollo/client';
import { UserPermissions } from 'Models/UserPermissions';
import { UserTypeOrder } from '../Enums';
// % protected region % [Add any further imports here] end

export interface IUserEntityAttributes extends IModelAttributes {
	email: string;
	userName: string;
	twoFactorEnabled: boolean;
	phone: string;
	creditApprovalLimit: number;
	isActive: boolean;
	firstName: string;
	canAccessPPSR: boolean;
	enableEmailNotifications: boolean;
	legacyID: number;
	canAccessApprove: boolean;
	canAccessIntel: boolean;
	lastName: string;
	userType: Enums.userType;
	permissionOverrides: string;

	atbFiles: Array<
		| Models.AtbFileEntity
		| Models.IAtbFileEntityAttributes
	>;
	customerAuditss: Array<
		| Models.CustomerAuditEntity
		| Models.ICustomerAuditEntityAttributes
	>;
	referredRegistrationss: Array<
		| Models.RegistrationEntity
		| Models.IRegistrationEntityAttributes
	>;
	registration?: Models.RegistrationEntity |
		Models.IRegistrationEntityAttributes;
	reportRequests: Array<
		| Models.ReportRequestEntity
		| Models.IReportRequestEntityAttributes
	>;
	businessEntitys: Array<
		| Models.BusinessEntityUser
		| Models.IBusinessEntityUserAttributes
	>;
	organisationId?: string;
	organisation?: Models.OrganisationEntity | Models.IOrganisationEntityAttributes;
	// % protected region % [Add any custom attributes to the interface here] on begin
	password?: string; // Used when creating a new user through GraphQL.
	// % protected region % [Add any custom attributes to the interface here] end
}

// % protected region % [Customise your entity metadata here] off begin
@entity('UserEntity', 'User')
// % protected region % [Customise your entity metadata here] end
export default class UserEntity extends Model
	implements IUserEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsUserEntity(),
		new UserUserEntity(),
		// % protected region % [Add any further ACL entries here] off begin
		// % protected region % [Add any further ACL entries here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
		// % protected region % [Add any custom create exclusions here] off begin
		// % protected region % [Add any custom create exclusions here] end
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
		'email',
		'userName',
		'twoFactorEnabled',
		// % protected region % [Add any custom update exclusions here] off begin
		// % protected region % [Add any custom update exclusions here] end
	];

	// % protected region % [Modify props to the crud options here for attribute 'Email'] off begin
	@Validators.Email()
	@observable
	@attribute()
	@CRUD({
		name: 'Email',
		displayType: 'displayfield',
		order: 10,
		createFieldType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public email: string;
	// % protected region % [Modify props to the crud options here for attribute 'Email'] end

	@observable
	@attribute()
	// % protected region % [Modify props to the crud options here for attribute 'TwoFactorEnabled'] off begin
	@CRUD({
		name: 'TwoFactorEnabled',
		displayType: 'displayfield',
		order: 20,
		createFieldType: 'textfield',
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public twoFactorEnabled: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'TwoFactorEnabled'] end

	// % protected region % [Modify props to the crud options here for attribute 'UserName'] off begin
	@observable
	@attribute()
	@CRUD({
		name: 'UserName',
		displayType: 'hidden',
	})
	public userName: string;
	// % protected region % [Modify props to the crud options here for attribute 'UserName'] end

	// % protected region % [Modify props to the crud options here for attribute 'Password'] off begin
	@Validators.Length(12, 128)
	@observable
	@CRUD({
		name: 'Password',
		displayType: 'hidden',
		order: 30,
		createFieldType: 'password',
	})
	public password: string;
	// % protected region % [Modify props to the crud options here for attribute 'Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] off begin
	@Validators.Custom('Password Match', (e: string, target: UserEntity) => {
		return new Promise(resolve => resolve(target.password !== e ? 'Password fields do not match' : null));
	})
	@observable
	@CRUD({
		name: 'Confirm Password',
		displayType: 'hidden',
		order: 40,
		createFieldType: 'password',
	})
	public _confirmPassword: string;
	// % protected region % [Modify props to the crud options here for attribute 'Confirm Password'] end

	// % protected region % [Modify props to the crud options here for attribute 'Phone'] off begin
	/**
	 * Phone
	 */
	@observable
	@attribute<UserEntity, string>()
	@CRUD({
		name: 'Phone',
		displayType: 'textfield',
		order: 50,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public phone: string;
	// % protected region % [Modify props to the crud options here for attribute 'Phone'] end

	// % protected region % [Modify props to the crud options here for attribute 'Credit Approval Limit'] off begin
	@Validators.Numeric()
	@observable
	@attribute<UserEntity, number>()
	@CRUD({
		name: 'Credit Approval Limit',
		displayType: 'textfield',
		order: 60,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseFloat,
	})
	public creditApprovalLimit: number;
	// % protected region % [Modify props to the crud options here for attribute 'Credit Approval Limit'] end

	// % protected region % [Modify props to the crud options here for attribute 'Is Active'] off begin
	/**
	 * Is Active
	 */
	@observable
	@attribute<UserEntity, boolean>()
	@CRUD({
		name: 'Is Active',
		displayType: 'checkbox',
		order: 70,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public isActive: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Is Active'] end

	// % protected region % [Modify props to the crud options here for attribute 'First Name'] off begin
	/**
	 * First Name
	 */
	@observable
	@attribute<UserEntity, string>()
	@CRUD({
		name: 'First Name',
		displayType: 'textfield',
		order: 80,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public firstName: string;
	// % protected region % [Modify props to the crud options here for attribute 'First Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'Can Access PPSR'] off begin
	/**
	 * Can Access PPSR
	 */
	@observable
	@attribute<UserEntity, boolean>()
	@CRUD({
		name: 'Can Access PPSR',
		displayType: 'checkbox',
		order: 90,
		headerColumn: true,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public canAccessPPSR: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Can Access PPSR'] end

	// % protected region % [Modify props to the crud options here for attribute 'Enable Email Notifications'] off begin
	@observable
	@attribute<UserEntity, boolean>()
	@CRUD({
		name: 'Enable Email Notifications',
		displayType: 'checkbox',
		order: 100,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public enableEmailNotifications: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Enable Email Notifications'] end

	// % protected region % [Modify props to the crud options here for attribute 'Legacy ID'] off begin
	@Validators.Integer()
	@observable
	@attribute<UserEntity, number>()
	@CRUD({
		name: 'Legacy ID',
		displayType: 'textfield',
		order: 110,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseInteger,
	})
	public legacyID: number;
	// % protected region % [Modify props to the crud options here for attribute 'Legacy ID'] end

	// % protected region % [Modify props to the crud options here for attribute 'Can Access Approve'] off begin
	/**
	 * Can Access Approve
	 */
	@observable
	@attribute<UserEntity, boolean>()
	@CRUD({
		name: 'Can Access Approve',
		displayType: 'checkbox',
		order: 120,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public canAccessApprove: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Can Access Approve'] end

	// % protected region % [Modify props to the crud options here for attribute 'Can Access Intel'] off begin
	/**
	 * Can Access Intel
	 */
	@observable
	@attribute<UserEntity, boolean>()
	@CRUD({
		name: 'Can Access Intel',
		displayType: 'checkbox',
		order: 130,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: AttrUtils.standardiseBoolean,
		displayFunction: attr => attr ? 'True' : 'False',
	})
	public canAccessIntel: boolean;
	// % protected region % [Modify props to the crud options here for attribute 'Can Access Intel'] end

	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] off begin
	/**
	 * Last Name
	 */
	@observable
	@attribute<UserEntity, string>()
	@CRUD({
		name: 'Last Name',
		displayType: 'textfield',
		order: 140,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public lastName: string;
	// % protected region % [Modify props to the crud options here for attribute 'Last Name'] end

	// % protected region % [Modify props to the crud options here for attribute 'User Type'] off begin
	/**
	 * User Type
	 */
	@observable
	@attribute<UserEntity, Enums.userType>()
	@CRUD({
		name: 'User Type',
		displayType: 'enum-combobox',
		order: 150,
		searchable: true,
		searchFunction: 'equal',
		searchTransform: (attr: string) => {
			return AttrUtils.standardiseEnum(attr, Enums.userTypeOptions);
		},
		enumResolveFunction: makeEnumFetchFunction(Enums.userTypeOptions),
		displayFunction: (attr: Enums.userType) => Enums.userTypeOptions[attr],
	})
	public userType: Enums.userType;
	// % protected region % [Modify props to the crud options here for attribute 'User Type'] end

	// % protected region % [Modify props to the crud options here for attribute 'Permission Overrides'] off begin
	/**
	 * Permission Overrides
	 */
	@observable
	@attribute<UserEntity, string>()
	@CRUD({
		name: 'Permission Overrides',
		displayType: 'textfield',
		order: 160,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public permissionOverrides: string;
	// % protected region % [Modify props to the crud options here for attribute 'Permission Overrides'] end

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'ATB File'] off begin
		name: 'ATB Files',
		displayType: 'reference-multicombobox',
		order: 170,
		referenceTypeFunc: () => Models.AtbFileEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'atbFiles',
			oppositeEntity: () => Models.AtbFileEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'ATB File'] end
	})
	public atbFiles: Models.AtbFileEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Customer Audits'] off begin
		name: 'Customer Auditss',
		displayType: 'reference-multicombobox',
		order: 180,
		referenceTypeFunc: () => Models.CustomerAuditEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'customerAuditss',
			oppositeEntity: () => Models.CustomerAuditEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Customer Audits'] end
	})
	public customerAuditss: Models.CustomerAuditEntity[] = [];

	/**
	 * Keep track of users who create referrals
	 */
	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Referred Registrations'] off begin
		name: 'Referred Registrationss',
		displayType: 'reference-multicombobox',
		order: 190,
		referenceTypeFunc: () => Models.RegistrationEntity,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'referredRegistrationss',
			oppositeEntity: () => Models.RegistrationEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Referred Registrations'] end
	})
	public referredRegistrationss: Models.RegistrationEntity[] = [];

	/**
	 * Registration which created this user
	 */
	@observable
	@attribute({ isReference: true, manyReference: false })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Registration'] off begin
		name: 'Registration',
		displayType: 'reference-combobox',
		order: 200,
		referenceTypeFunc: () => Models.RegistrationEntity,
		optionEqualFunc: (model, option) => model.id === option,
		inputProps: {
			fetchReferenceEntity: true,
		},
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'registrations',
			oppositeEntity: () => Models.RegistrationEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Registration'] end
	})
	public registration?: Models.RegistrationEntity;

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Report Request'] off begin
		name: 'Report Requests',
		displayType: 'reference-multicombobox',
		order: 210,
		referenceTypeFunc: () => Models.ReportRequestEntity,
		disableDefaultOptionRemoval: true,
		referenceResolveFunction: makeFetchOneToManyFunc({
			relationName: 'reportRequests',
			oppositeEntity: () => Models.ReportRequestEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Report Request'] end
	})
	public reportRequests: Models.ReportRequestEntity[] = [];

	@observable
	@attribute({ isReference: true, manyReference: true })
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Business Entity'] off begin
		name: 'Business Entity',
		displayType: 'reference-multicombobox',
		order: 220,
		isJoinEntity: true,
		referenceTypeFunc: () => Models.BusinessEntityUser,
		optionEqualFunc: makeJoinEqualsFunc('businessEntityId'),
		referenceResolveFunction: makeFetchManyToManyFunc({
			entityName: 'userEntity',
			oppositeEntityName: 'businessEntity',
			relationName: 'user',
			relationOppositeName: 'businessEntity',
			entity: () => Models.UserEntity,
			joinEntity: () => Models.BusinessEntityUser,
			oppositeEntity: () => Models.BusinessEntity,
		}),
		// % protected region % [Modify props to the crud options here for reference 'Business Entity'] end
	})
	public businessEntitys: Models.BusinessEntityUser[] = [];

	@observable
	@attribute()
	@CRUD({
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] off begin
		name: 'Organisation',
		displayType: 'reference-combobox',
		order: 230,
		referenceTypeFunc: () => Models.OrganisationEntity,
		// % protected region % [Modify props to the crud options here for reference 'Organisation'] end
	})
	public organisationId?: string;

	@observable
	@attribute({ isReference: true, manyReference: false })
	public organisation: Models.OrganisationEntity;

	// % protected region % [Add any custom attributes to the model here] on begin
	// Add custom getters and setters to convert string to JSON
	@computed get permissionOverridesObject(): UserPermissions | undefined {
		if (this.permissionOverrides === '' || this.permissionOverrides === null) {
			return undefined;
		}
		return new UserPermissions(JSON.parse(this.permissionOverrides));
	}

	set permissionOverridesObject(value: UserPermissions | undefined) {
		this.permissionOverrides = !value ? '' : JSON.stringify(value);
	}

	// allows us to use the setter in oneline
	public setPermission(userPermissions: UserPermissions, permission: string, value: (string | boolean | undefined)) {
		userPermissions[permission] = value;
		this.permissionOverridesObject = userPermissions;
	}
	// % protected region % [Add any custom attributes to the model here] end

	// eslint-disable-next-line @typescript-eslint/no-useless-constructor
	constructor(attributes?: Partial<IUserEntityAttributes>) {
		// % protected region % [Add any extra constructor logic before calling super here] off begin
		// % protected region % [Add any extra constructor logic before calling super here] end

		super(attributes);

		// % protected region % [Add any extra constructor logic after calling super here] off begin
		// % protected region % [Add any extra constructor logic after calling super here] end
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IUserEntityAttributes>) {
		// % protected region % [Override assign attributes here] off begin
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.email !== undefined) {
				this.email = attributes.email;
			}
			if (attributes.userName !== undefined) {
				this.userName = attributes.userName;
			}
			if (attributes.twoFactorEnabled !== undefined) {
				this.twoFactorEnabled = attributes.twoFactorEnabled;
			}
			if (attributes.phone !== undefined) {
				this.phone = attributes.phone;
			}
			if (attributes.creditApprovalLimit !== undefined) {
				this.creditApprovalLimit = attributes.creditApprovalLimit;
			}
			if (attributes.isActive !== undefined) {
				this.isActive = attributes.isActive;
			}
			if (attributes.firstName !== undefined) {
				this.firstName = attributes.firstName;
			}
			if (attributes.canAccessPPSR !== undefined) {
				this.canAccessPPSR = attributes.canAccessPPSR;
			}
			if (attributes.enableEmailNotifications !== undefined) {
				this.enableEmailNotifications = attributes.enableEmailNotifications;
			}
			if (attributes.legacyID !== undefined) {
				this.legacyID = attributes.legacyID;
			}
			if (attributes.canAccessApprove !== undefined) {
				this.canAccessApprove = attributes.canAccessApprove;
			}
			if (attributes.canAccessIntel !== undefined) {
				this.canAccessIntel = attributes.canAccessIntel;
			}
			if (attributes.lastName !== undefined) {
				this.lastName = attributes.lastName;
			}
			if (attributes.userType !== undefined) {
				this.userType = attributes.userType;
			}
			if (attributes.permissionOverrides !== undefined) {
				this.permissionOverrides = attributes.permissionOverrides;
			}
			if (attributes.atbFiles !== undefined && Array.isArray(attributes.atbFiles)) {
				for (const model of attributes.atbFiles) {
					if (model instanceof Models.AtbFileEntity) {
						this.atbFiles.push(model);
					} else {
						this.atbFiles.push(new Models.AtbFileEntity(model));
					}
				}
			}
			if (attributes.customerAuditss !== undefined && Array.isArray(attributes.customerAuditss)) {
				for (const model of attributes.customerAuditss) {
					if (model instanceof Models.CustomerAuditEntity) {
						this.customerAuditss.push(model);
					} else {
						this.customerAuditss.push(new Models.CustomerAuditEntity(model));
					}
				}
			}
			if (attributes.referredRegistrationss !== undefined && Array.isArray(attributes.referredRegistrationss)) {
				for (const model of attributes.referredRegistrationss) {
					if (model instanceof Models.RegistrationEntity) {
						this.referredRegistrationss.push(model);
					} else {
						this.referredRegistrationss.push(new Models.RegistrationEntity(model));
					}
				}
			}
			if (attributes.registration !== undefined) {
				if (attributes.registration === null) {
					this.registration = attributes.registration;
				} else if (attributes.registration instanceof Models.RegistrationEntity) {
					this.registration = attributes.registration;
				} else {
					this.registration = new Models.RegistrationEntity(attributes.registration);
				}
			}
			if (attributes.reportRequests !== undefined && Array.isArray(attributes.reportRequests)) {
				for (const model of attributes.reportRequests) {
					if (model instanceof Models.ReportRequestEntity) {
						this.reportRequests.push(model);
					} else {
						this.reportRequests.push(new Models.ReportRequestEntity(model));
					}
				}
			}
			if (attributes.businessEntitys !== undefined && Array.isArray(attributes.businessEntitys)) {
				for (const model of attributes.businessEntitys) {
					if (model instanceof Models.BusinessEntityUser) {
						this.businessEntitys.push(model);
					} else {
						this.businessEntitys.push(new Models.BusinessEntityUser(model));
					}
				}
			}
			if (attributes.organisationId !== undefined) {
				this.organisationId = attributes.organisationId;
			}
			if (attributes.organisation !== undefined) {
				if (attributes.organisation === null) {
					this.organisation = attributes.organisation;
				} else if (attributes.organisation instanceof Models.OrganisationEntity) {
					this.organisation = attributes.organisation;
					this.organisationId = attributes.organisation.id;
				} else {
					this.organisation = new Models.OrganisationEntity(attributes.organisation);
					this.organisationId = this.organisation.id;
				}
			}
			// % protected region % [Override assign attributes here] end

			// % protected region % [Add any extra assign attributes logic here] off begin
			// % protected region % [Add any extra assign attributes logic here] end
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	// % protected region % [Customize Default Expands here] off begin
	public defaultExpands = `
		businessEntitys {
			${Models.BusinessEntityUser.getAttributes().join('\n')}
			businessEntity {
				${Models.BusinessEntity.getAttributes().join('\n')}
			}
		}
		atbFiles {
			${Models.AtbFileEntity.getAttributes().join('\n')}
		}
		customerAuditss {
			${Models.CustomerAuditEntity.getAttributes().join('\n')}
		}
		referredRegistrationss {
			${Models.RegistrationEntity.getAttributes().join('\n')}
		}
		registration {
			${Models.RegistrationEntity.getAttributes().join('\n')}
		}
		reportRequests {
			${Models.ReportRequestEntity.getAttributes().join('\n')}
		}
		organisation {
			${Models.OrganisationEntity.getAttributes().join('\n')}
			${Models.OrganisationEntity.getFiles().map(f => f.name).join('\n')}
		}
	`;
	// % protected region % [Customize Default Expands here] end

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	// % protected region % [Customize Save From Crud here] off begin
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath: ReferencePath = {
			businessEntitys: {},
			atbFiles: {},
			customerAuditss: {},
			referredRegistrationss: {},
			registration: {},
			reportRequests: {},
		};

		if (formMode === 'create') {
			relationPath.password = {};

			if (this.password !== this._confirmPassword) {
				throw Error('Password fields do not match');
			}
		}
		return this.save(
			relationPath,
			{
				graphQlInputType: formMode === 'create'
					? `[${this.getModelName()}CreateInput]`
					: `[${this.getModelName()}Input]`,
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'customerAuditss',
							'referredRegistrationss',
							'registration',
							'businessEntitys',
						],
					},
				],
			},
		);
	}
	// % protected region % [Customize Save From Crud here] end

	/**
	 * Returns the string representation of this entity to display on the UI.
	 */
	public getDisplayName() {
		// % protected region % [Customise the display name for this entity] off begin
		return this.email;
		// % protected region % [Customise the display name for this entity] end
	}

	// % protected region % [Add any further custom model features here] on begin
	public static async fetchUser(userId: string) {
		try {
			const results = await store.apolloClient.query({
				query: gql`
					query fetchUserById($userId: ID) {
						userEntity(id: $userId) {
							${this.getAllAttributes().join('\n')}
							organisation {
								id
								approveEnabled
								ppsrEnabled
								intelEnabled
								primaryBusinessEntity {
									id
									name
								}
							}
							businessEntitys {
								id
								businessEntity {
									id
									name
								}
							}
						}
					}`,
				variables: {
					userId: userId,
				},
				fetchPolicy: 'no-cache',
			});

			return new this(results.data.userEntity);
		} catch {
			return undefined;
		}
	}

	get canEdit() {
		const currentUser = store.getUser;
		if (!currentUser) {
			return false;
		}

		if (UserTypeOrder[this.userType] > UserTypeOrder[currentUser.userType]) {
			return false;
		}

		const editScope = store.userPermissions.commonManageUsers;
		switch (editScope) {
			case 'ALL':
				return true;
			case 'WITHIN_ORGANISATION':
				return this.organisationId === currentUser.organisation?.id;
			case 'WITHIN_BUSINESS_ENTITIES':
				return !!this.businessEntitys && this.businessEntitys
					.map(x => x.businessEntityId)
					.some(x => currentUser.businessEntityIds?.includes(x));
			case 'SELF':
			default:
				return false;
		}
	}

	public isLastOrganisationManager = async () => {
		if (this.userType !== 'ORGANISATION_MANAGER') {
			return false;
		}

		const { data } = await store.apolloClient.query({
			query: gql`
				query userEntity($organisationId: String) {
					countOrganisationManagers: countUserEntitys(
						where: [
							{path: "userType", comparison: equal, value: ["ORGANISATION_MANAGER"]},
							{path: "isActive", comparison: equal, value: ["true"]},
							{path: "organisationId", comparison: equal, value: [$organisationId]}
						]
					) {
						number
					}
				}
			`,
			variables: {
				organisationId: this.organisationId,
			},
			fetchPolicy: 'no-cache',
		});
		return data.countOrganisationManagers.number === 1;
	};
	// % protected region % [Add any further custom model features here] end
}

// % protected region % [Modify the create and modified CRUD attributes here] off begin
/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(UserEntity.prototype, 'created');
CRUD(modifiedAttr)(UserEntity.prototype, 'modified');
// % protected region % [Modify the create and modified CRUD attributes here] end
