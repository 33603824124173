import {
	AddressEntity,
	CustomerAuditEntity,
	CustomerEntity,
	GroupEntity,
	GroupsCustomers,
} from 'Models/Entities';
import { Colors, Display } from 'Views/Components/Button/Button';
import { IconDisplay, IconTextBox } from 'Views/Components/TextBox/IconTextBox';
import React, { useCallback, useMemo, useState } from 'react';
import {
	acnClassOptions,
	acnStatusOptions,
	acnSubClassOptions,
	acnTypeOptions,
} from 'Models/Enums';
import { debounce, isEqual } from 'lodash';

import ButtonAsyncState from 'Views/Components/Button/ButtonAsyncState';
import { DatePicker } from 'Views/Components/DatePicker/DatePicker';
import GroupSelector from './GroupSelector';
import HandleEvents from 'Util/HandleEvents';
import If from 'Views/Components/If/If';
import InlineSpinner from 'Views/Components/Spinner/InlineSpinner';
import LiquorLicenceInformation from './LiquorLicenceSection/LiquorLicenceInformation';
import { TextField } from 'Views/Components/TextBox/TextBox';
import { Tooltip } from 'Views/Components/Tooltip/Tooltip';
import alertToast from 'Util/ToastifyUtils';
import classNames from 'classnames';
import moment from 'moment';
import { observer } from 'mobx-react';
import useAsync from 'Hooks/useAsync';
import useHasChanged from 'Hooks/useHasChanged';
import useStore from 'Hooks/useStore';
import { CreateCustomerAudit, GetCustomerAuditsDescription } from './CustomerAudits/Gibs/FetchCustomerAudits';

export interface CustomerDescription {
	title: string,
	message: string | null,
}

export interface BusinessInformationProps {
    customer: CustomerEntity,
}

const BusinessInformation = observer((props: BusinessInformationProps) => {
	const { customer } = props;
	const store = useStore();

	const editDisabled = useMemo(() => {
		return store.userPermissions.intelManageCustomerBusinessInformation !== true;
	}, [store]);

	const editGroupDisabled = useMemo(() => {
		return store.userPermissions.intelManageCustomerGroups !== true;
	}, [store]);

	const [sectionsToShow, setSectionsToShow] = useState({
		abnInfo: true,
		asicInfo: true,
		liquorLicenceInfo: true,
	});

	const [privateCustomer, setPrivateCustomer] = React.useState(() => {
		const newCustomer = new CustomerEntity(customer);
		newCustomer.physicalAddress = new AddressEntity(customer.physicalAddress);
		newCustomer.postalAddress = new AddressEntity(customer.postalAddress);
		return newCustomer;
	});

	const comparatorCallback = useCallback((oldValue, newValue) => {
		// check if the object is an array and has the property 'groupsId'
		if (Array.isArray(oldValue) && Array.isArray(newValue)
			&& oldValue.filter(x => Object.prototype.hasOwnProperty.call(x, 'groupsId')).length === oldValue.length
			&& newValue.filter(x => Object.prototype.hasOwnProperty.call(x, 'groupsId')).length === newValue.length) {
			// if we are comparing Groups we compare that the array is the same set of group Ids
			return !isEqual(
				oldValue.map(x => x.groupsId).sort(),
				newValue.map(x => x.groupsId).sort(),
			);
		}
		return !isEqual(oldValue, newValue); // we use isEqual to help with the dates
	}, []);

	const saveDisabled = useHasChanged(
		CustomerEntity,
		privateCustomer,
		// eslint-disable-next-line max-len
		['abn', 'acn', 'trusteeACN', 'businessName', 'debtorID', 'firstName', 'lastName', 'dateOfBirth', 'groupss', 'drinksLicence', 'drinksLicenceState', 'drinksLicenceInfo'],
		(oldValue, newValue) => comparatorCallback(oldValue, newValue),
		['errors'],
		(original, changed) => Object.keys(changed.errors).length > 0,
	);

	// run abn/asic validation on component mount
	const response = useAsync(async () => {
		await customer.runAbnValidation();
		await customer.runAcnValidation();
		await customer.runTrusteeACNValidation();
		await customer.runLiquorLicenceValidation();

		await onSave({
			useOriginal: true,
			raiseToast: false,
		});
	}, []);

	const validateDebtorId = useCallback(async () => {
		// if the debtorID matches what the current saved ID, we know it must be valid
		if (customer.debtorID === privateCustomer.debtorID) {
			privateCustomer.setFieldError('debtorID', false, '');
			return;
		}
		await privateCustomer.validateField('debtorID');
	}, [customer, privateCustomer]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedValidateDebtorId = useCallback(debounce(validateDebtorId, 300), [validateDebtorId]);

	const validateAbn = useCallback(async () => {
		await privateCustomer.validateField('abn');
	}, [privateCustomer]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedValidateAbn = useCallback(debounce(validateAbn, 300), [validateAbn]);

	const validateAcn = useCallback(async () => {
		const differThanTrusteeAcn = privateCustomer.checkAcnAndTrusteeAcnValue('acn');
		if (differThanTrusteeAcn) {
			await privateCustomer.validateField('acn');
			await privateCustomer.validateField('trusteeACN');
		}
	}, [privateCustomer]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedValidateAcn = useCallback(debounce(validateAcn, 300), [validateAcn]);

	const validateTrusteeACN = useCallback(async () => {
		const differThanAcn = privateCustomer.checkAcnAndTrusteeAcnValue('trusteeACN');
		if (differThanAcn) {
			await privateCustomer.validateField('trusteeACN');
			await privateCustomer.validateField('acn');
		}
	}, [privateCustomer]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedValidateTrusteeACN = useCallback(debounce(validateTrusteeACN, 300), [validateTrusteeACN]);

	const onSave = useCallback(async (options: {
		useOriginal?: boolean,
		raiseToast?: boolean,
	}) => {
		const { useOriginal = false, raiseToast = false } = options;
		const correctCustomer = (useOriginal ? customer : privateCustomer);
		correctCustomer.customerAuditss = [];

		// Add the customer audits for the changed sections.
		const customerAuditsDescription = GetCustomerAuditsDescription(
			customer,
			correctCustomer,
			'BUSINESS_INFORMATION',
		);

		if (customerAuditsDescription !== undefined) {
			const newCustomerAudit = CreateCustomerAudit(
				customer.id,
				'BUSINESS_INFORMATION',
				customerAuditsDescription,
			);
			correctCustomer.customerAuditss.push(newCustomerAudit);
		}

		try {
			// Set relation path without group entity
			const relationPath: {[key: string]: any} = {
				physicalAddress: {},
				postalAddress: {},
				customerAuditss: {},
			};

			// add groups to relation path if a new association between customer and group was created
			// or an associate was removed. There's no way a groupss information changes, as either a
			// new groupss is created or removed
			if (correctCustomer.groupss.some(gc => gc.id === undefined) || correctCustomer
				.groupss.length !== customer.groupss.length) {
				relationPath.groupss = {};
			}

			// Add groups to relationPath if a new group entity is created
			if (correctCustomer.groupss.some(gc => gc.groups.id === undefined)) {
				relationPath.groupss = {
					groups: {},
				};
			}

			await correctCustomer.saveWithFetchBack(relationPath, {
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
							'groupss',
						],
					},
				],
			}, `
					physicalAddress {
						${AddressEntity.getAttributes().join('\n')}
					}
					postalAddress {
						${AddressEntity.getAttributes().join('\n')}
					}
					groupss {
						${GroupsCustomers.getAttributes().join('\n')}
						groups {
							${GroupEntity.getAttributes().join('\n')}
						}
					}
					customerAuditss {
						${CustomerAuditEntity.getAttributes().join('\n')}
					}
				`);
			const newCustomer = new CustomerEntity(correctCustomer);
			newCustomer.physicalAddress = new AddressEntity(correctCustomer.physicalAddress);
			newCustomer.postalAddress = new AddressEntity(correctCustomer.postalAddress);
			setPrivateCustomer(newCustomer);
			customer.groupss.length = 0;
			customer.assignAttributes(newCustomer);
			if (raiseToast) {
				alertToast('Business information saved', 'success');
			}
		} catch {
			if (raiseToast) {
				alertToast('Business information could not be saved, please refresh and try again', 'error');
			}
		}
	}, [customer, privateCustomer]);

	const onChevronClick = (field: ('abnInfo' | 'asicInfo' | 'liquorLicenceInfo')) => {
		setSectionsToShow(old => {
			const copy = { ...old };
			copy[field] = !copy[field];
			return copy;
		});
	};

	if (response.type === 'loading') {
		return (
			<InlineSpinner />
		);
	}

	return (
		<div className="business-information">
			<h4>
				Business information
				<Tooltip
					id="abr-sync-tooltip"
					content="Where applicable, current data is synced from ABR and ASIC"
				/>
			</h4>
			<div className="inputs business-info">
				<div className="input-container">
					<TextField
						model={privateCustomer}
						modelProperty="businessName"
						className="business-name"
						isDisabled={editDisabled}
						label="Business name"
					/>
				</div>
				<div className="input-container">
					<TextField
						model={privateCustomer}
						modelProperty="firstName"
						className="first-name"
						isDisabled={editDisabled}
						label="First name"
					/>
				</div>
				<div className="input-container">
					<TextField
						model={privateCustomer}
						modelProperty="debtorID"
						errors={privateCustomer.errors.debtorID}
						onChangeAndBlur={validateDebtorId}
						onAfterChange={debouncedValidateDebtorId}
						className="debtor-id"
						label="Debtor ID"
						isDisabled={editDisabled}
					/>
				</div>
				<div className="input-container">
					<TextField
						model={privateCustomer}
						modelProperty="lastName"
						className="last-name"
						isDisabled={editDisabled}
						label="Last name"
					/>
				</div>
				<div className="input-container">
					<GroupSelector customer={privateCustomer} isDisabled={editGroupDisabled} />
				</div>
				<div className="input-container">
					<DatePicker
						className="date-of-birth"
						model={privateCustomer}
						modelProperty="dateOfBirth"
						label="Date of Birth"
						placeholder="Date of Birth"
					/>
				</div>
			</div>

			<div className="section-title-container">
				<h6 className="section-title">ABR Information</h6>
				<span
					className={classNames('accordion-icon icon-only icon-chevron-down', {
						open: sectionsToShow.abnInfo,
					})}
					{...HandleEvents(() => onChevronClick('abnInfo'))}
				/>
			</div>

			<If condition={sectionsToShow.abnInfo}>
				<div className="inputs">
					<div className="inputs-column stretch">
						<div className="abn-container input-container">
							<IconTextBox
								className="business-number abn"
								model={privateCustomer}
								modelProperty="abn"
								placeholder="ABN"
								onChangeAndBlur={validateAbn}
								onAfterChange={debouncedValidateAbn}
								errors={privateCustomer.errors.abn}
								isRequired
								displayIconContainer={privateCustomer.abnFinishedValidating}
								iconDisplay={privateCustomer.entityStatus !== '' && privateCustomer
									.entityStatus === 'Active' ? IconDisplay.Valid : IconDisplay.Error}
								isDisabled={editDisabled}
								label="ABN"
							/>
							<div className="status"><b>Status:</b> { !privateCustomer.entityStatus ? '-'
								: privateCustomer.entityStatus }<br />
							</div>
							<div className="type"><b>Type:</b> { !privateCustomer.entityTypeDescription ? '-'
								: privateCustomer.entityTypeDescription }<br />
							</div>
							<div className="entity-name"><b>Entity Name:</b> { !privateCustomer.entityName ? '-'
								: privateCustomer.entityName }<br />
							</div>
						</div>
						<div className="input-container">
							<TextField
								className="locality"
								model={{ locality: !privateCustomer.locality ? '-' : privateCustomer.locality }}
								modelProperty="locality"
								isDisabled
								label="Locality"
							/>
						</div>
					</div>

					<div className="inputs-column">
						<div className="input-container">
							<TextField
								className="active-since"
								model={{
									activeSince: !privateCustomer.activeSince
										? '-'
										: moment(privateCustomer.activeSince, 'DD-MM-YYYY').format('Do MMM YYYY'),
								}}
								modelProperty="activeSince"
								isDisabled
								label="Active since"
							/>
						</div>
						<div className="input-container">
							<TextField
								className="record-updated"
								model={{
									recordUpdated: !privateCustomer.recordUpdated
										? '-'
										: moment(privateCustomer.recordUpdated, 'DD-MM-YYYY').format('Do MMM YYYY'),
								}}
								modelProperty="recordUpdated"
								isDisabled
								label="Record updated"
							/>
						</div>
						<div className="input-container">
							<TextField
								className="registered-for-gst"
								model={{
									gst: !privateCustomer.registeredForGSTFrom
										? '-'
										// eslint-disable-next-line max-len
										: `Registered from ${moment(privateCustomer.registeredForGSTFrom, 'DD-MM-YYYY').format('Do MMM YYYY')}`,
								}}
								modelProperty="gst"
								isDisabled
								label="Goods & Services Tax (GST)"
							/>
						</div>
					</div>
				</div>
			</If>

			<div className="section-title-container">
				<h6 className="section-title">ASIC Information</h6>
				<span
					className={classNames('accordion-icon icon-only icon-chevron-down', {
						open: sectionsToShow.asicInfo,
					})}
					{...HandleEvents(() => onChevronClick('asicInfo'))}
				/>
			</div>

			<If condition={sectionsToShow.asicInfo}>
				<p className="type-title">ACN information</p>
				<div className="asic-acn inputs">
					<div className="inputs-column stretch">
						<div className="acn-container input-container">
							<IconTextBox
								className="business-number acn"
								model={privateCustomer}
								modelProperty="acn"
								placeholder="ACN"
								onChangeAndBlur={validateAcn}
								onAfterChange={debouncedValidateAcn}
								errors={privateCustomer.errors.acn}
								isRequired
								displayIconContainer={privateCustomer.acnFinishedValidating}
								iconDisplay={privateCustomer.acnStatus !== '' && privateCustomer.acnStatus === 'REGD'
									? IconDisplay.Valid : IconDisplay.Warning}
								isDisabled={editDisabled}
								label="ACN"
							/>
							<div className="status"><b>Status:</b> { !privateCustomer.acnStatus ? '-'
								: acnStatusOptions[privateCustomer.acnStatus] }<br />
							</div>
							<div className="type"><b>Type:</b> { !privateCustomer.acnEntityTypeCode ? '-'
								: acnTypeOptions[privateCustomer.acnEntityTypeCode] }<br />
							</div>
							<div className="entity-name"><b>Entity Name:</b> { !privateCustomer.acnEntityName ? '-'
								: privateCustomer.acnEntityName }<br />
							</div>
						</div>
					</div>

					<div className="inputs-column">
						<div className="input-container">
							<TextField
								className="registration-date"
								model={{
									dateOfRegistration: !privateCustomer.acnRegistrationDate
										? '-'
										// eslint-disable-next-line max-len
										: moment(privateCustomer.acnRegistrationDate, 'DD-MM-YYYY').format('Do MMM YYYY'),
								}}
								modelProperty="dateOfRegistration"
								isDisabled
								label="Date of registration"
							/>
						</div>
						<div className="input-container">
							<TextField
								className="class"
								model={{
									class: !privateCustomer.acnClass ? '-' : acnClassOptions[privateCustomer
										.acnClass],
								}}
								modelProperty="class"
								isDisabled
								label="Class"
							/>
						</div>
						<div className="input-container">
							<TextField
								className="sub-class"
								model={{
									subClass: !privateCustomer.acnSubClass ? '-' : acnSubClassOptions[privateCustomer
										.acnSubClass],
								}}
								modelProperty="subClass"
								isDisabled
								label="Sub Class"
							/>
						</div>
					</div>
				</div>

				<p className="type-title">Trustee ACN information</p>
				<div className="asic-trustee-acn inputs">
					<div className="inputs-column stretch">
						<div className="trustee-acn-container input-container">
							<IconTextBox
								className="business-number trustee-acn"
								model={privateCustomer}
								modelProperty="trusteeACN"
								placeholder="Trustee ACN"
								onChangeAndBlur={validateTrusteeACN}
								onAfterChange={debouncedValidateTrusteeACN}
								errors={privateCustomer.errors.trusteeACN}
								isRequired
								displayIconContainer={privateCustomer.trusteeACNFinishedValidating}
								iconDisplay={privateCustomer.trusteeACNStatus !== '' && privateCustomer
									.trusteeACNStatus === 'REGD' ? IconDisplay.Valid : IconDisplay.Warning}
								isDisabled={editDisabled}
							/>
							<div className="status"><b>Status:</b> { !privateCustomer.trusteeACNStatus ? '-'
								: acnStatusOptions[privateCustomer.trusteeACNStatus] }<br />
							</div>
							<div className="type"><b>Type:</b> { !privateCustomer.trusteeACNEntityTypeCode ? '-'
								: acnTypeOptions[privateCustomer.trusteeACNEntityTypeCode] }<br />
							</div>
							<div className="entity-name"><b>Entity Name:</b> { !privateCustomer.trusteeACNEntityName
								? '-' : privateCustomer.trusteeACNEntityName }<br />
							</div>
						</div>
					</div>

					<div className="inputs-column">
						<div className="input-container">
							<TextField
								className="registration-date"
								model={{
									dateOfRegistration: !privateCustomer.trusteeACNRegistrationDate ? '-' : moment(
										privateCustomer.trusteeACNRegistrationDate,
									).format('Do MMM YYYY'),
								}}
								modelProperty="dateOfRegistration"
								isDisabled
								label="Date of registration"
							/>
						</div>
						<div className="input-container">
							<TextField
								className="class"
								model={{
									class: !privateCustomer.trusteeACNClass ? '-' : acnClassOptions[privateCustomer
										.trusteeACNClass],
								}}
								modelProperty="class"
								isDisabled
								label="Class"
							/>
						</div>
						<div className="input-container">
							<TextField
								className="sub-class"
								model={{
									subClass: !privateCustomer.trusteeACNSubClass ? '-' : acnSubClassOptions[
										privateCustomer.trusteeACNSubClass],
								}}
								modelProperty="subClass"
								isDisabled
								label="Sub Class"
							/>
						</div>
					</div>
				</div>
			</If>

			<div className="section-title-container">
				<h6 className="section-title">Liquor Licence Information</h6>
				<span
					className={classNames('accordion-icon icon-only icon-chevron-down', {
						open: sectionsToShow.liquorLicenceInfo,
					})}
					{...HandleEvents(() => onChevronClick('liquorLicenceInfo'))}
				/>
			</div>

			<If condition={sectionsToShow.liquorLicenceInfo}>
				{/* eslint-disable-next-line max-len */}
				<LiquorLicenceInformation privateCustomer={privateCustomer} editDisabled={editDisabled} />
			</If>

			<ButtonAsyncState
				colors={Colors.Primary}
				display={Display.Solid}
				onPress={async () => onSave({
					useOriginal: false,
					raiseToast: true,
				})}
				readonly={saveDisabled}
				className="save"
			>
				Save changes
			</ButtonAsyncState>
		</div>
	);
});

export default BusinessInformation;
