import { useValidation, Validator } from "Hooks/useValidation";
import Modal from "Views/Components/Modal/Modal"
import LodgeDefaultForm, { FormItem } from "./LodgeDefaultForm";
import { Button, Colors, Display, Sizes } from "Views/Components/Button/Button"
import { ButtonGroup } from "Views/Components/Button/ButtonGroup"
import ButtonAsyncState from "Views/Components/Button/ButtonAsyncState"
import { useMemo, useState } from "react";
import axios from "axios";
import { SERVER_URL } from "Constants";
import alertToast from "Util/ToastifyUtils";
import If from "Views/Components/If/If";
import ComboboxSetter from "Views/Components/Combobox/ComboboxSetter";
import { Combobox, ComboboxOption } from "Views/Components/Combobox/Combobox";

type Props = {
    onClose: () => void;
}

const items: FormItem[] = [
	{ 
		name: 'recordType', 
		type: 'select', 
		selectOptions: [
			'New default', 
			'Update to an existing default'], 
		placeholder: '', 
		label: 'Record type', 
		isRequired: true, 
	},
	{ 
		name: 'companyNumber', 
		type: 'text', 
		placeholder: 'ABN or ACN', 
		label: 'Company number', 
		isRequired: true, 
	},
	{ 
		name: 'defaultDate', 
		type: 'date', placeholder: '', 
		label: 'Default date', 
		isRequired: true, 
	},
	{ 
		name: 'defaultReference', 
		type: 'text', placeholder: '', 
		label: 'Default reference', 
		isRequired: true, 
	},
	{ 
		name: 'accountType', 
		type: 'select', 
		selectOptions: [
			'(TF) Trade Finance - Trading arrangement',
			'(LT) Long Term Loan - Loan agreement for greater than one year',
			'(ST) Short Term Loan - Loan agreement for one year or less',
			'(CP) Credit card account',
			'(OC) Line of Credit - Ongoing credit account',
			'(OD) Overdraft facility',
			'(LA) Lease agreement',
			'(TC) Telecommunications',
			'(OT) Other Default]'],
		placeholder: '', 
		label: 'Account type', 
		isRequired: true, 
	},
	{
		name: 'reasonToReport',
		type: 'select',
		selectOptions: [
			'(SI) Serious Credit Infringement / Skip',
			'(C) Clearout (watched)',
			'(CX) Clearout (not watched)',
			'(DC) Dishonoured Cheque',
			'(II) Account opened on incorrect information supplied by customer',
			'(IS) Insurance shortfall',
			'(JD) Judgement Debt Outstanding',
			'(L) Legal (Where court action commenced)',
			'(OA) Overdrawn Account'
		],
		placeholder: '',
		label: 'Reason to report',
		isRequired: true,
	},
	{
		name: 'originalAmount',
		type: 'currency',
		placeholder: '',
		label: 'Original amount',
		isRequired: true,
		validate: (validator: Validator<string | null | undefined>) => {
			validator.assertGreaterThan(0, true)
		}
	},
	{
		name: 'outstandingAmount',
		type: 'currency',
		placeholder: '',
		label: 'Outstanding amount',
		isRequired: true,
		validate: (validator: Validator<string | null | undefined>) => {
			validator.assertGreaterThan(0, true)
		}
	},
	{
		name: 'defaultStatus',
		type: 'select',
		selectOptions: [
			'(L) Outstanding ',
			'(R) Partially Paid',
			'(S) Settled',
			'(P) Paid in Full'
		],
		placeholder: '',
		label: 'Default status',
		isRequired: true,
	},
	{
		name: 'termsAndDisclaimer',
		type: 'hidden',
		placeholder: '',
		label: 'Terms and disclaimer',
		isRequired: true,
		validate: (validator: Validator<string | null | undefined>) => {
			validator.assertTruthy("Please accept terms and disclaimer")
		}
	}
]

export const LodgeDefaultModal:React.FunctionComponent<Props> = ({ onClose}) => {
	const initialFieldValues = useMemo(() => {
		const values = items.reduce((acc, item) => {
			acc[item.name] = '';
			return acc;
		}, {} as Record<string, string>);
		// remove the items that are not required as per the current items key
		Object.keys(values).forEach(key => {
			if (!items.find(item => item.name === key)) {
				delete values[key];
			}
		});

		return values;
	}, []);

	const yesNoOptions = [
		{ value: 2, display: 'Yes' }, 
		{ value: 1, display: 'No' }
	] as ComboboxOption<number>[];

	const [textFields, setTextFields] = useState<Record<string, string>>(initialFieldValues);
	const [showForm, setShowForm] = useState(false);
	const [showInfoMessage, setShowInfoMessage] = useState(false);
	const [isSetUpForDefaults, setIsSetUpForDefaults] = useState(0);

	const validationConfig = items.reduce((acc, item) => {
		const fieldName = item.name;
		acc[fieldName] = {
			value: textFields[fieldName],
			isRequired: item.isRequired,
			requiredText: item.requiredText ?? (item.label + " is required"),
			validate: item.validate
		};
		return acc;
	}, {} as Record<string, { 
		value: string; 
		isRequired: boolean; 
		requiredText?: string, 
		validate?: (validator: Validator<string | null | undefined>) => void 
	}>);

	const validation = useValidation(validationConfig, false);

	async function onNotSetUp() {
		try {
			const response = await axios.post<boolean>(`${SERVER_URL}/api/support-tickets?category=LodgeDefaultSetup`);

			if (response.data) {
				alertToast('Request sent successfully.', 'success');
				onClose();
			} else {
				alertToast('An error occurred while processing your request, If error persist send this details via Email at processing@accessintell.com', 'error');
			}
		} catch (error) {
			if (axios.isAxiosError(error)) {
				alertToast('An error occurred while processing your request. Please try again!', 'error');
			} else {
				alertToast('An unexpected error occurred', 'error');
			}
		}
	}

	async function onSubmit() {
		if (validation.errorCount > 0 || !validation.isValid) {
			alertToast('Please fill all required fields before submitting a request', 'error');
			return;
		}

		try {
			const formData = JSON.stringify(textFields)
			const response = await axios.post<boolean>(`${SERVER_URL}/api/support-tickets?category=LodgeDefault`, {
				lodgeDefaultDetail: formData,
			});

			if (response.data) {
				alertToast('Default successfully lodged', 'success');
				onClose();
			} else {
				alertToast('An error occurred while processing your request, If error persist send this details via Email at processing@accessintell.com', 'error');
			}
		} catch (error) {
			if (axios.isAxiosError(error)) {
				alertToast('An error occurred while processing your request. Please try again!', 'error');
			} else {
				alertToast('An unexpected error occurred', 'error');
			}
		}
	}

	return(
		<Modal className="property-search-form lodge-default-form" isOpen={true} label="Lodge a Default">
			<h4>Lodge a Default</h4>

			<If condition={!showForm && !showInfoMessage}>
				<span className="txt-body">Are you currently set up to lodge defaults?</span>
				<br />
				<ComboboxSetter
					key="isSetUpForDefaults"
					label=""
					isRequired={false}
					options={yesNoOptions}
					getOptionValue={v => v}
					value={isSetUpForDefaults}
					setValue={v => setIsSetUpForDefaults(v)}
				/>
			</If>
			<If condition={showInfoMessage && !showForm}>
				<span className="txt-body">Thank you for your interest. One of our team members will contact you shortly.</span>
			</If>
			<If condition={showForm && !showInfoMessage}>
				<LodgeDefaultForm
					items={items}
					validation={validation}
					textFields={textFields}
					setTextFields={setTextFields}
				/>
			</If>
			<ButtonGroup className="modal__actions">
				<Button
					colors={Colors.Error}
					sizes={Sizes.Medium}
					display={Display.Text}
					onClick={onClose}
				>
					{showInfoMessage ? 'Close' : 'Cancel'}
				</Button>
				<If condition={!showInfoMessage && !showForm}>
					<If condition={isSetUpForDefaults !== 1}>
						<Button
							colors={Colors.Primary}
							sizes={Sizes.Medium}
							display={Display.Solid}
							onClick={() => setShowForm(true)}
							disabled={isSetUpForDefaults !== 2}
						>
							Next
						</Button>
					</If>
					<If condition={isSetUpForDefaults === 1}>
						<ButtonAsyncState
							colors={Colors.Primary}
							sizes={Sizes.Medium}
							display={Display.Solid}
							onPress={onNotSetUp}
							readonly={isSetUpForDefaults !== 1}
							waitingText="Sending request..."
						>
							Request Setup
						</ButtonAsyncState>
					</If>
				</If>
				<If condition={showForm}>
					<ButtonAsyncState
						colors={Colors.Primary}
						sizes={Sizes.Medium}
						display={Display.Solid}
						onPress={onSubmit}
						readonly={validation.errorCount > 0 || !validation.isValid}
						waitingText="Submitting..."
					>
						Submit
					</ButtonAsyncState>
				</If>
			</ButtonGroup>
		</Modal>
	)
}