import { useValidation } from "Hooks/useValidation"
import { state, stateOptions } from "Models/Enums"
import React, { useState } from "react"
import { Button, Colors, Display, Sizes } from "Views/Components/Button/Button"
import { ButtonGroup } from "Views/Components/Button/ButtonGroup"
import { ComboboxOption } from "Views/Components/Combobox/Combobox"
import ComboboxSetter from "Views/Components/Combobox/ComboboxSetter"
import Modal from "Views/Components/Modal/Modal"
import { TextFieldSetter } from "Views/Components/TextBox/TextFieldSetter"
import { PropertySearchByRegionSection } from "./PropertySearchByRegionSection"
import { PropertySearchByTitleOptionForRegion } from "./PropertySearchByRegionType"
import ButtonAsyncState from "Views/Components/Button/ButtonAsyncState"
import alertToast from "Util/ToastifyUtils"
import axios from "axios"
import { SERVER_URL } from "Constants"

type Props = {
	onClose: () => void;
}

export const RequestPropertySearchModel:React.FunctionComponent<Props> = ({ onClose }) => {
	const [fileReference, setFileReference] = useState<string>('');
	const [region, setRegion] = useState<state>('NONE');
	const [searchBy, setSearchBy] = useState<PropertySearchByTitleOptionForRegion>();
	const [searchByDetailInfo, setSearchByDetailInfo] = useState<string>();

	const validation = useValidation({
		fileReference: {
			value: fileReference,
			isRequired: true,
			requiredText: 'File Reference is required',
		},
		region: {
			value: region,
			isRequired: true,
			requiredText: 'State is required',
			validate: v => (v.assertRule(value => value !== 'NONE', 'State is required')),
		},
		searchBy: {
			value: searchBy,
			isRequired: true,
			requiredText: 'Please select a search option',
		},
		searchByDetailInfo: {
			value: searchByDetailInfo,
			isRequired: true,
			requiredText: '',
			validate: v => {
				switch (searchBy) {
					case 'lot-plan':
						v.assertLengthBetween(1, 20, 'Invalid Lot/Plan number. Must be between 1 and 20 characters');
						break;
					case 'volume-folio':
					case 'title-reference':
					case 'organisation-owner':
					case 'individual-owner':
					case 'address':
					case 'parcel':
					case 'previous-title-reference':
					case 'lot-town':
					case 'plan-parcel':
						v.assertTruthy('This field is required');
						break;
				}
			}
		},
	});

	const availableState = (Object.keys(stateOptions) as [state])
		.map<ComboboxOption<state>>(value => {
			return {
				display: value === 'NONE' ? 'Select State' : stateOptions[value],
				value: value,
			};
		});
	
	async function handlePropertySearchRequest() {
		if (validation.errorCount > 0 || !validation.isValid) {
			alertToast('Please fill all required fields before submitting a request', 'error');
		}

		try {
			const response = await axios.post<boolean>(`${SERVER_URL}/api/support-tickets?category=PropertySearch`, {
				fileReference: fileReference,
				region: region,
				propertyType: searchBy,
				propertyDetail: searchByDetailInfo,
			});

			if (response.data) {
				alertToast('Successfully submitted property search request. Our team will contact you once your request is processed.', 'success');
				onClose();
			} else {
				alertToast('An error occurred while processing your request, If error persist send this details via Email at processing@accessintell.com', 'error');
			}
		} catch (error) {
			if (axios.isAxiosError(error)) {
				alertToast('An error occurred while processing your request. Please try again!', 'error');
			} else {
				alertToast('An unexpected error occurred', 'error');
			}
		}
	}

	return (
		<Modal className="property-search-form" isOpen={true} label="Request Property Search Form">
			<h4>Request Property Search Form</h4>
			<TextFieldSetter
				value={fileReference}
				setValue={setFileReference}
				label="File Reference"
				placeholder="Enter matter file reference"
				isRequired
				errors={validation.get("fileReference", false)}
			/>
			<ComboboxSetter
				value={region}
				setValue={data => {setRegion(data); setSearchBy(undefined); setSearchByDetailInfo(undefined)}}
				label="Select Region"
				options={availableState}
				getOptionValue={option => option}
				isRequired
				errors={validation.get("region", false)}
				searchable={false}
			/>
			{region !== 'NONE' && region !== 'VIC' && (
				<PropertySearchByRegionSection
					searchBy={searchBy}
					setSearchBy={setSearchBy}
					selectedRegion={region}
					searchByDetailInfo={searchByDetailInfo ?? ''}
					setSearchByDetailInfo={setSearchByDetailInfo}
					errors={validation.get("searchByDetailInfo", false)}
				/>
			)}
			{region === 'VIC' && (
				<div className="landata-agreement-section">
					<p>
						Landata Agreement must be signed before access to Victorian Property searches is granted.
					</p>
					<Button
						colors={Colors.Primary}
						sizes={Sizes.Medium}
						display={Display.Solid}
						onClick={() => window.open(`${SERVER_URL}/api/files/document/property-search-victoria`, '_blank', 'noreferrer')}
					>
						Click here for additional details
					</Button>
				</div>
			)}
			<ButtonGroup className="modal__actions">
				<Button
					colors={Colors.Error}
					sizes={Sizes.Medium}
					display={Display.Text}
					onClick={onClose}
				>
					Cancel
				</Button>

				<ButtonAsyncState
					colors={Colors.Primary}
					sizes={Sizes.Medium}
					display={Display.Solid}
					readonly={!validation.isValid}
					onPress={handlePropertySearchRequest}
				>
					Submit Request
				</ButtonAsyncState>
			</ButtonGroup>
		</Modal>
	)
}
