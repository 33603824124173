import axios from 'axios';
import { SERVER_URL } from 'Constants';
import { observer } from 'mobx-react';
import { store } from 'Models/Store';
import * as React from 'react';
import { useState } from 'react';
import alertToast from 'Util/ToastifyUtils';
import { Button, Display } from 'Views/Components/Button/Button';
import If from '../If/If';
import Modal from '../Modal/Modal';
import { NumberTextField } from '../NumberTextBox/NumberTextBox';
import RadioButtonGroupSetter from '../RadioButton/RadioButtonGroupSetter';
import { TextArea } from '../TextArea/TextArea';
import { TextField } from '../TextBox/TextBox';
export interface PpsrNZProps {
	readonly?: boolean,
	onClose: () => void,
}

export type RequestPpsrNZFields = {
	spgNumber: {data?: string, error?: string},
	customerNZBN: {data?: string, error?: string},
	customerName: {data?: string, error?: string},
	infoText: {data?: string, error?: string},
}

const PpsrNZFields = observer((props: PpsrNZProps) => {
	const { onClose } = props;

	const [ReqPpsrNZFields, setReqPpsrNZFields] = useState<RequestPpsrNZFields>({
		spgNumber: { data: undefined},
		customerName: { data: undefined},
		customerNZBN: { data: undefined},
		infoText: { data: undefined},
	});
	
	const [, setHasDisabled] = useState(true);
	const handleSubmit = async () => {
		// check if has submit abn is yes or no
		// if yes
		if (customerHasABN === 'Yes')
		{
			try {
				const requestBody = {
					userName: store.userDisplayName,
					userEmail: store.email,
					spgNumber: ReqPpsrNZFields.spgNumber.data,
					customerName: ReqPpsrNZFields.customerName.data,
					customerNzbn: ReqPpsrNZFields.customerNZBN.data,
				}
				await axios.post(`${SERVER_URL}/api/nz-ppsr/submit`, requestBody);
			} catch (e) {
				alertToast('Could not send the email please try again', 'error');
				return;
			}
			alertToast('Sent an email successfully', 'success');
		} // if no 
		else {
			try {
				const requestBody = {
					userName: store.userDisplayName,
					userEmail: store.email,
					customerInfo: ReqPpsrNZFields.infoText.data,
				}
				await axios.post(`${SERVER_URL}/api/nz-ppsr/submit`, requestBody);
			} catch (e) {
				alertToast('Could not send the email please try again', 'error');
				return;
			}
			alertToast('Sent an email successfully', 'success');
		}
		onClose();
	};
	const validateField = (field: keyof RequestPpsrNZFields) => {
		let error;
		const fieldData = ReqPpsrNZFields[field].data;
		switch (field) {
			case 'spgNumber':
				if (fieldData === undefined) error = 'SPG Number cannot be empty';
				if (!fieldData) error = 'SPG Number cannot be empty';
				break;
			case 'customerNZBN':
				if (!fieldData) error = 'Customer NZBN cannot be empty';
				else if (fieldData.toString().replace(/\D/g, '').length > 13) error = 'Customer NZBN cannot be more than 13 digits';
				else if (fieldData.toString().replace(/\D/g, '').length < 13) error = 'Customer NZBN cannot be less than 13 digits';
				else if ((fieldData.toString().replace(/\D/g, '').charAt(0) !== '9')) error = 'Customer NZBN in not valid'
				else if ((fieldData.toString().replace(/\D/g, '').charAt(1) !== '4')) error = 'Customer NZBN in not valid'
				break;
			case 'customerName':
				if (!fieldData) error = 'Customer Name cannot be empty';
				break;
			case 'infoText':
				if (!fieldData) error = 'This field cannot be empty';
				break;
		}
		return error;
	};
	const handleUpdateTemplateFields = (field: keyof RequestPpsrNZFields, value: string | number ) => {
		const error = validateField(field);
		setHasDisabled(!!error);
		setReqPpsrNZFields({ ...ReqPpsrNZFields, [field]: { data: value, error: error } });
		return;
	};
	
	const handleErrorState =  () => {
		if (customerHasABN === '')
		{
			return true;
		}
		if (customerHasABN === 'Yes') {
			if (ReqPpsrNZFields.spgNumber.data === undefined || ReqPpsrNZFields.customerNZBN.data === undefined || ReqPpsrNZFields.customerName.data === undefined) {
				return true;
			}
			if (ReqPpsrNZFields.spgNumber.error || ReqPpsrNZFields.customerNZBN.error || ReqPpsrNZFields.customerName.error) {
				return true;
			}
		}
		if (customerHasABN === 'No' && (ReqPpsrNZFields.infoText.error || ReqPpsrNZFields.infoText.data === undefined)) {
			return true;
		}
		else return false
	};

	// used for switching between the radio buttons
	const [customerHasABN, setcustomerHasABN] = React.useState(() => {
		return '';
	});
	return (
		<Modal
			isOpen
			label="Setup NZ PPSR"
			onRequestClose={onClose}
			className="access-modal"
		>
			<h4> Setup NZ PPSR</h4>
			<div className="inputs">
				<div className="inputs-column stretch">
					<RadioButtonGroupSetter
						className="trusteeRadio"
						value={customerHasABN}
						setValue={setcustomerHasABN}
						options={[
							{ value: 'Yes', display: 'Yes, I am registered' },
							{ value: 'No', display: 'No' },
						]}
						label="Are you currently registered on New Zealand PPSR? "
						isRequired
						isDisabled={false}
					/>
					<If condition={customerHasABN === 'Yes'}>
						<div>
							<NumberTextField
								model={ReqPpsrNZFields.spgNumber}
								modelProperty="data"
								label="SPG Number"
								placeholder="SPG Number"
								onAfterChange={event => handleUpdateTemplateFields('spgNumber', event.target.value)}
								errors={ReqPpsrNZFields.spgNumber.error}
								isRequired
							/>
						</div>
						<div>
							<NumberTextField
								model={ReqPpsrNZFields.customerNZBN}
								modelProperty="data"
								label="Customer NZBN"
								placeholder="Customer NZBN"
								onAfterChange={event => handleUpdateTemplateFields('customerNZBN', event.target.value)}
								errors={ReqPpsrNZFields.customerNZBN.error}
								isRequired
							/>
						</div>
						<div>
							<TextField
								model={ReqPpsrNZFields.customerName}
								modelProperty="data"
								label="Customer Name"
								placeholder="Customer Name"
								onAfterChange={event => handleUpdateTemplateFields('customerName', event.target.value)}
								errors={ReqPpsrNZFields.customerName.error}
								isRequired
							/>
						</div>
					</If>
					<If condition={customerHasABN === 'No'}>
						<TextArea
							model={ReqPpsrNZFields.infoText}
							modelProperty="data"
							label="Please provide a message, one of our team will be in contact shortly to set up "
							placeholder="Please provide a message"
							onAfterChange={event => handleUpdateTemplateFields('infoText', event.target.value)}
							errors={ReqPpsrNZFields.infoText.error}
							isRequired
						/>
					</If>
				</div>
			</div>
			<div key="actions" className="modal__actions">
				<Button key="cancel" onClick={onClose} display={Display.Outline}>Close</Button>
				<Button key="cancel" onClick={handleSubmit} display={Display.Solid} disabled={handleErrorState()}>Send Email</Button>
			</div>
		</Modal>
	);
});

export default PpsrNZFields;

