
import { ApplicationEntity, BusinessEntity } from "Models/Entities";
import { store } from "Models/Store";
import { useState } from "react";
import alertToast from "Util/ToastifyUtils";
import EditApproveDetailsModal from "../BusinessEntities/EditApproveDetailsModal";
import If from "../If/If";
import { BusinessEntitySelector } from "./BusinessEntitySelector";
import SectionTitle from "./SectionTitle";

export const ApproveSection = () => {
	const isSuper = store.userType === 'SUPER_USER';
	const isManager = store.userType === 'ORGANISATION_MANAGER';
	const isBusinessEntityAdmin = store.userType === 'ADMIN';
	const isApproveEnabled = store.getUser?.organisation?.approveEnabled;
	const showApproveSetup = (isSuper || isManager || (isBusinessEntityAdmin && isApproveEnabled)) && store.canAccessApprove;
	const [showBusinessEntitySelectorModal, setShowBusinessEntitySelectorModal] = useState(false);
	const [showProductModal, setShowProductModal] = useState(false);
	const [businessEntity, setBusinessEntity] = useState<BusinessEntity | undefined>(undefined); 

	const openModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.preventDefault();
		setShowBusinessEntitySelectorModal(true);
	}

	const [showValidateCustomerFields, setShowValidateCustomerFields] = useState(false);
	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const link = e.currentTarget.querySelector('a');
		if (link) {
			link.click();
		}
	}

	const setLinkClass = () => {
		return store.canAccessApprove ? "hub-link" : "hub-inactive-link";
	  };

	  console.log(!isApproveEnabled);

	return (
		<>
			<SectionTitle
				Link={store.canAccessApprove
					? `${store.appSettings.approveBaseUrl}${store.appSettings.approveLoginPath}`
					: store.appSettings.approveMarketingUrl}
				Text="Approve"
				DisplayButton={!isApproveEnabled}
			/>
			<div className="action-content extra-margin">
				<div className={setLinkClass()}>
					<div className="icon-left icon-person-add" />
					<a href={store.appSettings.approveBaseUrl + "/index.php?page=Customers_AE.php"} 
						target="_blank" 
						rel="noreferrer">
						Create customer
					</a>
				</div>
				<div className={setLinkClass()}>
					<div className="icon-left icon-credit-card" />
					<a href={store.appSettings.approveBaseUrl + "/index.php?page=Credit_Reports_Generate.php&Button_Selected=Credit_Reports"} 
						target="_blank" 
						rel="noreferrer">
						Generate credit report
					</a>
				</div>
				<div className={setLinkClass()}>
					<div className="icon-left icon-arrow-right" />
					<a href={store.appSettings.approveBaseUrl + "/index.php?page=Customers_AE.php"} 
						target="_blank" 
						rel="noreferrer">
						Send application
					</a>
				</div>
				<If condition={showApproveSetup}>
					<div className="hub-link" onClick={isApproveEnabled ? openModal : handleClick}>
						<div className="icon-left icon-settings" />
						<a href="/hub/organisation/products" target="_blank">
							Configure digital application
						</a>
					</div>
				</If>
			</div>

			<If condition={showBusinessEntitySelectorModal}>
				<BusinessEntitySelector
					onClose={() => { setShowBusinessEntitySelectorModal(false); setBusinessEntity(undefined);}}
					onSelect={() => { setShowBusinessEntitySelectorModal(false); setShowProductModal(true) }}
					businessEntity={businessEntity}
					setBusinessEntity={setBusinessEntity}
					productFilter="approve"
				/>
			</If>

			<If condition={showProductModal && businessEntity !== null}>
				<EditApproveDetailsModal
					businessEntity={businessEntity ?? new BusinessEntity()}
					onClose={() => { setShowProductModal(false); setBusinessEntity(undefined); }}
					onSubmit={async () => {
						try {
							const savedEntity = await businessEntity?.saveWithFetchBack({
								applicationss: {},
							}, {
								contentType: 'multipart/form-data',
							}, `
								applicationss {
									${ApplicationEntity.getAttributes().join('\n')}
									${ApplicationEntity.getFiles().map(f => f.name).join('\n')}
								}
							`);
							setBusinessEntity(undefined);
							setShowProductModal(false);
							alertToast('Access Approve successfully enabled', 'success');
						} catch (exception) {
							alertToast('Access Approve could not be enabled, please refresh and try again', 'error');
						}
					}}
					addNew
				/>
			</If>
		</>
	)
}