import { BusinessEntity } from "Models/Entities";
import { Button, Display } from "../Button/Button";
import ComboboxSetter from "../Combobox/ComboboxSetter";
import Modal from "../Modal/Modal";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { store } from "Models/Store";
import { ComboboxOption } from "../Combobox/Combobox";


export type BusinessEntitySelectorProps = {
    onClose: () => void;
    onSelect: () => void;
    businessEntity: BusinessEntity | undefined;
    setBusinessEntity: (be: BusinessEntity) => void,
	productFilter?: "ppsr" | "approve" | "monitor" | undefined
}

export const BusinessEntitySelector = ({ 
	onClose, 
	onSelect,
	businessEntity, 
	setBusinessEntity,
	productFilter
}: BusinessEntitySelectorProps) => {
	const currentUser = store.getUser;
	const getBusinessEntityOptions = async (searchTerm?: string): Promise<ComboboxOption<string>[]> => {
		try {
			let userBusinessEntities = currentUser?.organisation?.businessEntitys
				.filter(x => currentUser?.businessEntityIds?.includes(x.id))
				|| [];
			
			switch(productFilter) {
				case "monitor":
					userBusinessEntities = userBusinessEntities.filter(x => !x.enabledForMonitor);
					break;

				case "ppsr":
					userBusinessEntities = userBusinessEntities.filter(x => !x.enabledForPPSR);
					break;

				case "approve":
					userBusinessEntities = userBusinessEntities.filter(x => !x.enabledForApprove);
					break;

				default:
					break;
			}
    
			if(searchTerm) {
				userBusinessEntities = userBusinessEntities
					.filter(x => x.name.toLowerCase().includes(searchTerm.toLowerCase()));
			}
			return userBusinessEntities
				.map(x => {
					const be = new BusinessEntity(x);
					return {
						display: be.name,
						value: be.id,
					};
				});
		} catch (e) {
			return [];
		}
	};

	return(
		<Modal
			isOpen
			label="Select Business Entity"
			onRequestClose={onClose}
			className="access-modal"
		>
			<h4>Select Business Entity</h4>
			<ComboboxSetter
				label=""
				labelVisible={false}
				value={businessEntity?.id}
				setValue={value => {
					setBusinessEntity(new BusinessEntity(
						currentUser?.organisation?.businessEntitys.find(x => x.id === value)));
					onSelect();
				}}
				getOptionValue={(value?: string) => value}
				initialOptions={getBusinessEntityOptions}
				options={AwesomeDebouncePromise(getBusinessEntityOptions, 250)}
				searchable
				placeholder="Select a business entity to enable this product for"
			/>
			<div key="actions" className="modal__actions">
				<Button 
					key="cancel" 
					onClick={onClose} 
					display={Display.Outline}>
					Cancel
				</Button>
			</div>
		</Modal>
	);
}